import { SearchService } from '../../../../services/search.service';
import { Component, ElementRef, Inject, NgZone, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MatExpansionPanel } from '@angular/material/expansion';
import { SearchLevel } from 'app/models/search-school.model';
import { Subject, Subscription, debounceTime, distinctUntilChanged, filter } from 'rxjs';
import { Location } from '@angular/common';
import { AuthService, UtilityService } from 'app/services';
import { ActivatedRoute, Router } from '@angular/router';
import { UserStateEnum } from 'app/microservice-clients/user';

enum InputTypes {
  selectSchoolType = 'selectSchoolType',
  selecSchoolYear = 'selecSchoolYear',
  selectSubject = 'selectSubject',
  postCode = 'postCode',
  none = 'none'
}
@Component({
  selector: 'app-bottom-search',
  templateUrl: './bottom-search.component.html',
  styleUrls: ['./bottom-search.component.scss'],
})
export class BottomSearchComponent {

  dynamicHeight: string;
  selectedInput: InputTypes;
  postCodeInputChange = new Subject()
  postCodeInputChangeSubscription: Subscription
  @ViewChild('postCodeInput') postCodeInput: ElementRef;
  @ViewChild('schooltypePanel') schooltypePanel: MatExpansionPanel;
  @ViewChild('subjectsPanel') subjectsPanel: MatExpansionPanel;
  @ViewChildren(MatExpansionPanel) expansionPanels!: QueryList<MatExpansionPanel>;

  private observer: IntersectionObserver;
  private target: ElementRef;
  moveSticky: boolean = true;

  get UserStateEnum(): typeof UserStateEnum {
    return UserStateEnum;
  }

  constructor(
    public searchService: SearchService,
    public authService: AuthService,
    private bottomSheetRef: MatBottomSheetRef<BottomSearchComponent>,
    private utilityService: UtilityService,
    public location: Location,
    private router: Router,
    private route: ActivatedRoute,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    private el: ElementRef,
    private zone: NgZone
  ) {
    this.utilityService.addOpenQueryParam('isSearchOpen', this.route, this.router);
  }

  ngAfterViewInit() {
    this.applyPostCodeFilter();
    this.selectOnStart();
    this.obesrveElement();
  }

  ngOnDestroy(): void {
    // @ts-ignore
    this.observer.unobserve(this.target);
  }

  obesrveElement() {
    this.target = this.el.nativeElement.querySelector('.observed');

    this.zone.runOutsideAngular(() => {
      this.observer = new IntersectionObserver(this.handleIntersect.bind(this), {
        root: null,
        threshold: 0.1
      });
      // @ts-ignore
      this.observer.observe(this.target);
    });
  }

  handleIntersect(entries: IntersectionObserverEntry[], observer: IntersectionObserver): void {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        this.onShow();
      } else {
        this.onHide();
      }
    });
  }

  onShow(): void {
    this.zone.run(() => {
      this.moveSticky = true;
    });
  }

  onHide(): void {
    this.zone.run(() => {
      this.moveSticky = false;
    });
  }

  adjustSchoolYearContainerHeight() {
    this.dynamicHeight = this.searchService.selectedSchoolLevel?.years ? `${Math.floor((this.searchService.selectedSchoolLevel.years.length + 1) / 2) * 40}px` : `0px`
  }

  onSchoolLevelSelect(level: SearchLevel) {
    this.searchService.onLevelSelect(level);
    if (this.searchService.selectedSchoolLevel && !this.searchService.selectedSchoolLevel?.years) {
      this.nextPanel(this.schooltypePanel);
    }
    this.adjustSchoolYearContainerHeight()
  }

  onPanelExpanded(event: boolean, element: MatExpansionPanel) {
    if (event) {
      this.expansionPanels.filter(panel =>
        panel.id !== element.id
      ).forEach(panel => panel.close())
    }
    this.adjustSchoolYearContainerHeight()
  }

  nextPanel(element: MatExpansionPanel) {
    element.close()
    if (element.id == this.expansionPanels.last.id) {
      this.searchService.isOnline ? this.search() : this.postCodeInput.nativeElement.focus()
    }
    else {
      const nextPanel = this.expansionPanels.toArray().indexOf(element) + 1
      this.expansionPanels.toArray()[nextPanel].open()
    }
  }

  closeAllPanels() {
    this.expansionPanels.forEach(panel => panel.close())
  }

  selectOnStart() {
    this.selectedInput = this.data.selectedInput
    switch (this.selectedInput) {
      case InputTypes.selectSchoolType: this.schooltypePanel.open()
        break;
      case InputTypes.selecSchoolYear: this.schooltypePanel.open()
        break;
      case InputTypes.selectSubject: this.subjectsPanel.open()
        break;
      case InputTypes.postCode: this.postCodeInput.nativeElement.focus()
        break;
    }
  }

  applyPostCodeFilter() {
    this.postCodeInputChangeSubscription = this.postCodeInputChange.pipe(
      debounceTime(1000),
      distinctUntilChanged(),
      filter((postCode: string) => postCode.length >= 5)
    ).subscribe(() => {
      this.searchService.validatePostCodeAndSetCoordinates()
    });
  }

  closeBottomSheet() {
    this.bottomSheetRef.dismiss()
  }

  search() {
    this.data.search()
    this.closeBottomSheet()
  }
}