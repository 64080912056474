import { Component, EventEmitter, Output } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-delete-appointment-dialog',
  templateUrl: './delete-appointment-dialog.component.html',
  styleUrls: ['./delete-appointment-dialog.component.scss']
})
export class DeleteAppointmentDialogComponent {
  @Output() onConfirm = new EventEmitter();
  @Output() onCancel = new EventEmitter();

  constructor(
    public dialogRef: MatDialogRef<DeleteAppointmentDialogComponent>
  ) { }

  submit(): void {
    this.onConfirm.emit();
  }

  cancel(): void {
    this.onCancel.emit();
  }
}