
export interface Appointment {
	id: string,
	type: string,
	begin: string,
	end: string,
	series: boolean,
	seriesId: string,
	patternCount: number,
	patternUnit: string,
	patternData: string,
	modified: boolean,
	student: AppointmentUserInformation,
	tutor: AppointmentUserInformation,
	status: string,
	creditsStudent: number,
	amountStudentUnit: string,
	amountStudent: number,
	amountTutor: number,
	amountStudentTravel: number,
	amountTutorTravel: number,
	tutorLevel: number,
	tutorLevelName: string,
	distance: number,
	duration: number,
	online: boolean,
	locationLatitude: number,
	locationLongitude: number,
	locationName: string,
	locationAddress: string,
	locationCity: string,
	locationZip: string,
	course: number,
	courseName: string,
	createdAt: string,
	updatedAt: string,
	DeletedAt: string,
	bookedBy: number,
	_cancellable: boolean
	_googleMapsLink: string
}

export interface RelationAppointment {
	id: string,
	tutorID: string,
	studentID: string,
	name: string,
	date: string,
	time: string,
	duration: number,
	online: boolean,
	status: string,
	intro: boolean,
	course?: string,
	courseID?: string,
	cancellable: boolean,
	isCredits: boolean;
	value: number
	model: Appointment
}

export interface AppointmentUserInformation {
	id: string,
	email: string,
	surname: string,
	forename: string,
	profileImage: string
	tutorCategory: number

	//used and generated on UI
	_image: string
}

export interface AppointmentUserInformation {
	id: string,
	email: string,
	surname: string,
	forename: string,
	profileImage: string

	//used and generated on UI
	_image: string
}


