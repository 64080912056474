import { DOCUMENT, Location } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { PageTemplate } from './models';
import { AuthService, FeedbackFlowForIntroService, PageLoadService, PopupService, ToastService, UtilityService } from './services';
import * as constants from './constants';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, AfterViewInit {
  title = '';
  @ViewChild('messageInsert', { read: ViewContainerRef })
  messageInsert: ViewContainerRef;

  currentLang = '';
  currentPath = '';

  wsData$: Observable<any>;
  // loadingError$ = new Subject<boolean>();
  lastPageTemplate: PageTemplate;

  constructor(
    public popupService: PopupService,
    public pageLoadService: PageLoadService,
    private router: Router,
    public toastService: ToastService,
    translate: TranslateService,
    @Inject(DOCUMENT) private document: Document,
    private location: Location,
    public utilityService: UtilityService,
    private authService: AuthService,
    private feedbackFlowForIntroService: FeedbackFlowForIntroService,
    private cdr: ChangeDetectorRef) {
    this.currentLang = this.utilityService.country;

    translate.setDefaultLang(this.currentLang);
    translate.use(this.currentLang);
    this.title = constants.Brand[this.currentLang].name;

    this.router.events.subscribe((val) => {
      if (val instanceof NavigationStart) {
        let url = val['url'];
        this.utilityService.trackEvent('virtualPageView', url);
        this.pageLoadService.initPageTemplate(url);
      }
    });
    if (!this.utilityService.isBrowser) {
      this.pageLoadService.initPageTemplate(this.location.path());
    }
  }

  ngOnInit(): void {
    this.document.documentElement.lang = this.currentLang;
    this.currentPath = this.location.path();
    // this trackEvent call is required to init GTM
    // without it we're unable to use non-code GTM events for instance
    // including the first pageview as the event trackEvent('virtualPageView', url)
    // above doesn't capture the first page load 
    this.utilityService.trackEvent('event', 'GTM init');

    this.utilityService.onFullScreenChange.subscribe(() => this.cdr.detectChanges());

    //TODO refactor
    this.checkIfUserIsLoggedInAndHasIntroFeedback();

    this.utilityService.onFooterVisibilityChange.subscribe((isVisible) => {
      this.cdr.detectChanges();
    })
  }

  private checkIfUserIsLoggedInAndHasIntroFeedback() {
    if (this.utilityService.isBrowser && this.authService.isStudent()) {
      this.openFeedbackDialogIfRequired();

      this.authService.onCurrentUserChange.subscribe(() => {
        this.openFeedbackDialogIfRequired();
      });
    }
  }

  private openFeedbackDialogIfRequired() {
    const introFeedbackDialog = localStorage?.getItem('introFeedbackDialog');
    //TODO refactor
    if (introFeedbackDialog && !location.href.includes("lessonspace")) {
      this.feedbackFlowForIntroService.openFeedbackDialog(JSON.parse(introFeedbackDialog));
    }
  }

  ngAfterViewInit(): void {
    this.toastService.viewContainerRef = this.messageInsert;
  }

  pageLoadAction(pageTemplate: PageTemplate) {
    if (pageTemplate && this.lastPageTemplate != pageTemplate) {
      this.lastPageTemplate = pageTemplate;
      this.pageLoadService.setPage(pageTemplate);
      // } else if (!pageTemplate) {
      // console.log('app.component pageLoadAction', pageTemplate);
    }
    return pageTemplate;
  }
}
