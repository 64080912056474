import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent {

  constructor(public dialogRef: MatDialogRef<ConfirmDialogComponent>) { }
  // TODO REMOVE THIS
  @Input() text: string;
  @Input() confirmButtonText: string = 'texts.Confirm';
  @Input() cancelButtonText: string = 'texts.Cancel';
  @Input() hideCancelButton: boolean = false;
  @Output() onCancel = new EventEmitter();
  @Output() onConfirm = new EventEmitter();

  confirm() {
    this.onConfirm.emit();
    this.dialogRef.close()
  }

  cancel() {
    this.onCancel.emit();
    this.dialogRef.close()
  }

}
