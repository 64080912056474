import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'creditFormatter' })
export class CreditFormatterPipe implements PipeTransform {

    transform(value: any): string {
        if (value === null || value === undefined) {
            return value;
        }
        const valueString = typeof value === 'string' ? value : value.toString();
        const valueStringArray: Array<string> = valueString.split('.');
        valueStringArray[1] = valueStringArray.length > 1 ? this.addIfNeedDecimal(valueStringArray[1]) : '00';
        valueStringArray[1] = valueStringArray[1].substring(0, 2);
        if (valueStringArray[0].length > 3) {
            valueStringArray[0] = this.thousandSeparator(valueStringArray[0]);
        }
        const formattedValue = valueStringArray.join(',');
        return formattedValue;
    }

    private addIfNeedDecimal(value: string): string {
        return value.length === 1 ? value + '0' : value;
    }

    private thousandSeparator(integerPart: string): string {
        // Add thousand separators to the integer part
        const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
        return formattedIntegerPart;
    }
}