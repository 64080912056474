import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogRef } from '@angular/material/dialog';
import { UserObj } from 'app/microservice-clients/user';
import { LetsStartNewPackageComponent } from '../lets-start-new-package/lets-start-new-package.component';
import { SharedModule } from 'app/modules/shared/shared.module';

@Component({
  selector: 'app-lets-start-package-dialog',
  standalone: true,
  imports: [CommonModule, LetsStartNewPackageComponent, SharedModule],
  templateUrl: './lets-start-package-dialog.component.html',
  styleUrls: ['./lets-start-package-dialog.component.scss']
})
export class LetsStartPackageDialogComponent {

  constructor(public dialogRef: MatDialogRef<LetsStartPackageDialogComponent>) { }

  @Input() tutorObj: UserObj;
  @Input() showPictureAndSvg: boolean;
  @Input() showTutorLevelOptions: boolean;
  @Input() applyCustomStyling: boolean;
  @Output() onNewPackagePurchase = new EventEmitter();

  close() {
    this.dialogRef.close();
  }
}
