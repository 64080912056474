import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogRef } from '@angular/material/dialog';
import { SharedModule } from 'app/modules/shared/shared.module';
import { InstallmentOptionEnum } from 'app/models/package.model';

@Component({
  selector: 'app-select-payment-type',
  standalone: true,
  imports: [CommonModule, SharedModule],
  templateUrl: './select-payment-type.component.html',
  styleUrls: ['./select-payment-type.component.scss']
})
export class SelectPaymentTypeComponent {

  constructor(
    private dialogRef: MatDialogRef<SelectPaymentTypeComponent>,
  ) { }

  selectedInstallMentOption: InstallmentOptionEnum;
  @Input() durationMonths: number;
  @Output() onPaymentOptionConfirmed = new EventEmitter<InstallmentOptionEnum>();

  get InstallmentOptionEnum(): typeof InstallmentOptionEnum {
    return InstallmentOptionEnum;
  }

  close() {
    this.dialogRef.close();
  }
}
