<div class="faq-list" [ngClass]="{'chat-page-styling': isChatPage}">
  <div class="faq-list__title">
    {{ faqTitle }}
  </div>
  <mat-accordion *ngIf="faqListItems" class="faq-list__accordion">
    <ng-container *ngFor="let faqItem of faqListItems">
      <mat-expansion-panel class="expansion-panel" hideToggle>
        <mat-expansion-panel-header class="expansion-panel__header">
          <mat-panel-title class="expansion-panel__title">
            {{ faqItem.title }}
          </mat-panel-title>
          <div class="expansion-panel__icon expansion-panel__icon--closed">
            <i class="fas fa-plus"></i>
          </div>
          <div class="expansion-panel__icon expansion-panel__icon--expanded">
            <i class="fas fa-minus"></i>
          </div>
        </mat-expansion-panel-header>
        <div class="expansion-panel__content" [bindHTML]="faqItem.content"></div>
        <ng-container *ngIf="isChatPage">
          <div class="expansion-panel__content subtitle-text" [bindHTML]="faqItem.subTitle1"></div>
          <div class="expansion-panel__content subcontent-text" [bindHTML]="faqItem.subContent1"></div>
          <div class="expansion-panel__content subtitle-text" [bindHTML]="faqItem.subTitle2"></div>
          <div class="expansion-panel__content subcontent-text" [bindHTML]="faqItem.subContent2"></div>
          <div class="expansion-panel__content subtitle-text" [bindHTML]="faqItem.subTitle3"></div>
          <div class="expansion-panel__content subcontent-text" [bindHTML]="faqItem.subContent3"></div>
          <div class="expansion-panel__content subtitle-text" [bindHTML]="faqItem.subTitle4"></div>
          <div class="expansion-panel__content subcontent-text" [bindHTML]="faqItem.subContent4"></div>
        </ng-container>
      </mat-expansion-panel>
    </ng-container>
  </mat-accordion>
</div>