import { AuthService } from 'app/services';
import { CookieService } from 'ngx-cookie';
import { Injectable } from "@angular/core";

@Injectable()
export class TokenValidationService {

    constructor(
        private authService: AuthService,
        private cookieService: CookieService,
    ) { }

    validate() {
        return new Promise(res => {
            if (!this.cookieService.get('access')) {
                this.authService.refresh().subscribe(
                    () => res(true),
                    () => res(false))
            } else {
                res(true)
            }

        })
    }
}