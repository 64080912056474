import { ChatService } from './chat.service';
import { WebsocketService } from './websocket.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { CookieService } from 'ngx-cookie';
import { EMPTY, Observable, of, Subject } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { UserRole, BypassAPIGlobalHandleEnums } from '../constants';
import { EmailCheckResponse, SocialSignInResponse, User } from '../models';
import { UtilityService } from './utility.service';
import { TranslateService } from '@ngx-translate/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { UserObj } from 'app/microservice-clients/user';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  user!: User;

  set userDetails(value: UserObj) {
    this._userDetails = value;
    if (this._userDetails?.tutorDetails) {
      this._userDetails.tutorDetails = { ...this._userDetails.tutorDetails, _canTeachOnline: this._userDetails.tutorDetails.canTeachOnline === "true", _acceptsNewStudents: this._userDetails.tutorDetails.acceptsNewStudents === "true" }
    }
    localStorage?.setItem('userDetails', JSON.stringify(this._userDetails));
  };

  get userDetails(): UserObj {
    return this._userDetails;
  }

  anonymousId: string;

  @Output() onCurrentUserChange = new EventEmitter<UserObj>();

  private _userDetails: UserObj;
  private canRefresh = true;
  private refreshing = false;
  private refreshed = new Subject<string | null>();

  constructor(
    private apiService: ApiService,
    private chatService: ChatService,
    private cookieService: CookieService,
    private httpClient: HttpClient,
    private location: Location,
    private router: Router,
    private translationService: TranslateService,
    private utilityService: UtilityService,
    private websocketService: WebsocketService
  ) {
    if (this.utilityService.isBrowser) {
      this.setUserFromAccessToken();
    }
  }

  get isAdminImpersonating(): boolean {
    return !!this.user?.adminId;
  }

  get isSessionActive(): boolean {
    return !!this.cookieService.get('access');
  }

  get isAuthenticated(): Observable<boolean | string> {
    return this.user ? of(true) : this.refresh();
  }

  setUserFromAccessToken(accessToken?: string): void {
    accessToken = accessToken ? accessToken : this.cookieService.get('access');
    if (accessToken) {
      this.setUser(accessToken);
      this.userDetails = JSON.parse(localStorage?.getItem('userDetails'));;
    } else {
      localStorage.removeItem('userDetails')
      let _anonymousId = localStorage?.getItem('anonymousId');
      if (!_anonymousId) {
        _anonymousId = this.utilityService.generateUuid();
        localStorage?.setItem('anonymousId', _anonymousId);
      }
      this.anonymousId = _anonymousId;
    }
  }

  updatePassword(password) {
    return this.httpClient.put(`${this.utilityService.apiBase}/auth/user/${this.user.id}/password`, { 'password': password })
  }

  getUserDetails() {
    let route = `${this.utilityService.apiBase}/user/${this.user.id}?cacheBust=${Date.now()}`
    return this.httpClient.get<UserObj>(route).pipe(tap(resp => {
      this.userDetails = resp;
    }))
  }

  //NOTE: send only the updated fields + id
  updateUserDetails(user: UserObj): Observable<UserObj> {
    return this.httpClient.put<UserObj>(`${this.utilityService.apiBase}/user/${this.user.id}`, user).pipe(
      catchError(() => {
        return EMPTY;
      }),
      tap(resp => {
        this.userDetails = resp
      })
    )
  }

  login(email: string, password: string, recaptcha: string): Observable<{ access: string }> {
    const loginData = {
      email,
      password,
      captcha: recaptcha,
      country: this.utilityService.country
    };
    const headers = this.utilityService.addHeaders(BypassAPIGlobalHandleEnums.All);

    return this.apiService.postWithHeaders(`auth/login`, loginData, headers).pipe(
      tap(response => {
        this.setUser(response.access ? response.access : '');
        this.associateAnonymousUserWithSearch();

        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());
        if (params.returnUrl) {
          this.router.navigateByUrl(params.returnUrl)
        } else {
          this.translationService.get([
            'ROUTES.Misc_login',
            'ROUTES.Misc_dashboard',
            'ROUTES.Misc_Dashboard_Student',
            'ROUTES.Misc_Dashboard_Tutor'
          ]).subscribe(links => {
            if (this.location.path().includes('401') || this.location.path().includes(links['ROUTES.Misc_login'])) {
              this.router.navigate([`/${links['ROUTES.Misc_dashboard']}/${(this.isStudent()) ? links['ROUTES.Misc_Dashboard_Student'] : links['ROUTES.Misc_Dashboard_Tutor']}`]);
            }
          });
        }
      })
    );
  }

  loginWithGoogle() {
    this.httpClient.get(`${this.utilityService.apiBase}/auth/login/google/${this.utilityService.country}`).subscribe({
      next: (response: SocialSignInResponse) => {
        this.saveRouteOnGoogleAuth();
        window.location.href = response.url;
      }
    });
  }

  private saveRouteOnGoogleAuth() {
    let wasSet: boolean = true;
    try {
      localStorage.setItem('routeAfterGAlogin', this.router.url)
    } catch (error) {
      wasSet = false;
    }
    return wasSet;
  }

  validateEmailAddress(email: string, recaptcha: string): Observable<any> {
    const url = `${this.utilityService.apiBase}/user/mail/check`;

    const params = new HttpParams()
      .set('captcha', recaptcha)
      .set('email', email);

    const headers = this.utilityService.addHeaders(BypassAPIGlobalHandleEnums.All);
    const options = {
      params: params,
      headers: headers
    }

    return this.httpClient.get(url, options).pipe(
      map((response: EmailCheckResponse) => response)
    );
  }

  parseJwt(token: string): User {
    return JSON.parse(atob(
      token.split('.')[1].replace('-', '+').replace('_', '/')
    )
    );
  }

  logout() {
    this.httpClient.get(`${this.utilityService.apiBase}/auth/logout`).subscribe((response) => {
      this.clearSession();
      this.onCurrentUserChange.emit(null)
      this.websocketService.close();
    });
  }

  clearSession() {
    this.cookieService.remove('access');
    this.cookieService.remove('refresh');
    this.cookieService.remove('refresh_possible');
    this.user = null;
    this.userDetails = null;
    if (this.utilityService.isBrowser) {
      localStorage.removeItem('userId')
      localStorage.removeItem('userDetails');
    }
    this.chatService.close();
  }

  private setUser(access: string): void {
    this.user = this.parseJwt(access);
    if (this.utilityService.isBrowser) {
      localStorage.setItem('userId', this.user.id.toString())
      this.chatService.init(this.user);
      // THIS SET TIMEOUT IS NEEDED DUE TO ONE SSR ISSUE
      setTimeout(() => {
        const userDetails = JSON.parse(localStorage?.getItem('userDetails'));
        if (userDetails) {
          this._userDetails = userDetails;
          return;
        }
        this.getUserDetails().subscribe(resp => {
          this.onCurrentUserChange.emit(resp);
        });
      });
    }
  }

  refresh(): Observable<string | null> {
    if (this.refreshing) {
      return this.refreshed.asObservable();
    } else if (!this.canRefresh || !this.cookieService.hasKey('refresh_possible')) {
      return of(null);
    }
    this.refreshing = true;
    return this.httpClient.get<{ access: string }>('/auth/refresh').pipe(
      tap(resp => {
        if (resp.access) {
          this.setUser(resp.access);
          //  TODO THING IF THERE IS A REFRESH TOKEN WE MIGHT HAVE THE USERDETAILS IN LOCAL STORAGE
        }
        this.refreshing = false;
        this.refreshed.next(resp.access);
      }),
      map(resp => {
        return resp.access ?? null
      }),
      catchError((error) => {
        this.canRefresh = false;
        this.refreshing = false;
        this.refreshed.next(null);
        return of(null);
      })
    );
  }

  getUser() {
    return this.user;
  }

  isAdmin(): boolean {
    return this.user && this.user.role == UserRole.admin;
  }

  isStudent(): boolean {
    return this.user && this.user.role == UserRole.student;
  }

  isTeacher(): boolean {
    return this.user && this.user.role == UserRole.teacher;
  }

  isSignedIn(): boolean {
    return this.user && this.user.role > UserRole.signedout;
  }

  isCustomer(): boolean {
    return this.user && this.user.role > UserRole.signedout && this.user.role < UserRole.admin;
  }

  setImage(image: string) {
    this.user.image = image;
  }

  private associateAnonymousUserWithSearch() {
    this.apiService.put(`user/search-history/${this.anonymousId}/associate`, {}).subscribe();
  }

}