export interface UserPackageResponseModel extends PackageRecommendationModel {
    userId: string,
    createdAt: string,
    startDate: string,
    endDate: string,
    invoiceId: string
    packageId: string,
    packageName: string,
    status: number,
    balance: string,
    coursesLeft: string,
    userPackageId: string,
    price: number,
    regularPrice: number
}

export interface PackageRecommendationModel extends PackageRecommendationRequestModel {
    packageId: string,
    tutorTypeText: string,
    regularPrice: number,
    name: string
    discountId: number,
    discountPercent: number,
    discountPercentInt: string,
    price: number,
    credits: number
    totalLessons: number,
    beforeHourlyPrice: number
    lessonPrice: number,
    tutorCategory: string,
    maxDurationMonths: number,
    totalDiscountAmount: number,
    packageName?: string,
    freeLessonAmount: number,
    freeLessonCount: string,
    amountForDiscountPercent: string,
    pricePerMonth: number,
    examPackage?: boolean,
}
export interface PackageSubscriptionRequestModel {
    improvementPurpose: string
    packageId: string
    lessonsPerWeek: string
    tutorCategory: string
    durationMonths: string
    examPackage?: boolean
    installmentOption?: InstallmentOptionEnum
}

export interface ExamSubscriptionRequestModel {
    tutorCategory: string
    installmentOption?: InstallmentOptionEnum
}

export enum InstallmentOptionEnum {
    single = 'single',
    multi = 'multi'
}

export interface PackageRecommendationRequestModel {
    durationMonths: string,
    lessonsPerWeek: string,
    tutorCategory: string,
    country: string,
    improvementPurpose: string,
    savingsPerMonth?: string,
}

export interface UserPackages {
    packages: Array<UserPackageResponseModel>
}
export interface PackageDetails {
    durationMonths: string;
    savePerMonth?: string;
    lessonsPerWeek?: string;
    costPerHour?: string;
    tutorCategory?: number;
    packageId?: string;
    pricePerMonth?: number;
    _model?: any;
    _totalPrice?: number;
    _totalLessonsInHours?: number;
}