import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
@Component({
  selector: 'app-cancel-appointment-dialog',
  templateUrl: './cancel-appointment-dialog.component.html',
  styleUrls: ['./cancel-appointment-dialog.component.scss']
})
export class CancelAppointmentDialogComponent {

  @Input() appointment: any;
  @Input() isTeacher: boolean;

  @Output() onConfirm = new EventEmitter();
  @Output() onCancel = new EventEmitter();

  constructor(
    public dialogRef: MatDialogRef<CancelAppointmentDialogComponent>
  ) { }

  submit(): void {
    this.onConfirm.emit();
  }

  cancel(): void {
    this.onCancel.emit();
  }

}
