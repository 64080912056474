<div class="cta-container">

    <div class="cta-title" [ngClass]="{'price-page-style': applyCustomStyling}" translate>
        {{ applyCustomStyling ? 'texts.Buy_Package_After_Booking_Title_Pricing_Page' :
        'texts.Buy_Package_After_Booking_Title'
        }}
    </div>
    <div *ngIf="showPictureAndSvg" class="svgs-container">
        <div class="svg-and-name-container">
            <div class="round-svg-container">
                <app-svg-icon name="User_Hand_Up"></app-svg-icon>
            </div>
            <div class="svg-name" [translateParams]="{name: tutorName}" translate>
                texts.Buy_Package_After_Booking_First_Action</div>
        </div>
        <div class="line-container">
            <svg width="330" height="34" viewBox="0 0 330 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M329 25.25C173.685 55.4606 137.412 -21.8218 0.999999 8.24999" stroke="#5cc75f" stroke-width="4"
                    stroke-dasharray="10 10" />
            </svg>
        </div>
        <div class="svg-and-name-container">
            <div class="round-svg-container">
                <app-svg-icon name="Square_Academic_Cap_Gray"></app-svg-icon>
            </div>
            <div class="svg-name" translate>texts.Buy_Package_After_Booking_Second_Action</div>
        </div>
        <div class="line-container">
            <svg width="330" height="34" viewBox="0 0 330 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M329 25.25C173.685 55.4606 137.412 -21.8218 0.999999 8.24999" stroke="#B4BACB" stroke-width="4"
                    stroke-dasharray="10 10" />
            </svg>
        </div>
        <div class="svg-and-name-container">
            <div class="round-svg-container">
                <app-svg-icon name="Cup"></app-svg-icon>
            </div>
            <div class="svg-name" translate>texts.Buy_Package_After_Booking_Third_Action</div>
        </div>
    </div>
    <div class="recommendation-container">
        <div class="recommendation"><app-svg-icon class="icon" name="Unread"></app-svg-icon> <span
                translate>texts.Buy_Package_After_Booking_Advantages_List1</span></div>
        <div class="recommendation"
            *ngIf="authService.userDetails?.studentDetails?.expectedTutoringDuration !== '0' && !showOnly5HourPackage">
            <app-svg-icon name="Unread"></app-svg-icon><span
                translate>texts.Buy_Package_After_Booking_Advantages_List2</span>
        </div>
        <div class="recommendation"><app-svg-icon name="Unread"></app-svg-icon><span
                translate>texts.Buy_Package_After_Booking_Advantages_List3</span></div>
    </div>
    <div class="separator" [ngClass]="{'price-page-style': applyCustomStyling}"></div>
    <div *ngIf="authService.userDetails?.studentDetails?.expectedTutoringDuration !== '0' && !showOnly5HourPackage"
        class="lessons-per-week-container" [ngClass]="{'price-page-style': applyCustomStyling}">
        <div class="lesson-per-week-title" translate>texts.Buy_Package_After_Booking_Number_Of_Lessons_Question</div>
        <div class="buttons-container">
            <button *ngFor="let lesson of lessonsPerWeek" class="small-button" (click)="lessonsPerWeekChange(lesson)"
                [ngClass]="{'small-button-active': lesson.value === selectedLessonPerWeek, 'favorite': lesson.value === '2'}">
                <div class="most-chosen" *ngIf="lesson.value === '2'" translate>
                    texts.Buy_Package_After_Booking_Most_Chosen</div>
                <div class="toggle-button" [(class.toggle-button-active)]="lesson.value === selectedLessonPerWeek">
                </div>
                <div class="button-text">
                    {{lesson.textMonthly|translate}}
                </div>
            </button>
        </div>
        <div class="lesson-per-week-info" translate>texts.Buy_Package_After_Booking_Number_Of_Lessons_Info</div>
    </div>
    <div class="hidden"
        [ngClass]="{'show-tutor-level-options': showTutorLevelOptions && selectedLessonPerWeek || (showTutorLevelOptions && (authService.userDetails?.studentDetails?.expectedTutoringDuration === '0' || showOnly5HourPackage))}">
        <div *ngIf="showTutorLevelOptions && selectedLessonPerWeek || (showTutorLevelOptions && (authService.userDetails?.studentDetails?.expectedTutoringDuration === '0' || showOnly5HourPackage))"
            class="tutor-levels-container" [@fadeInOut]="true" [ngClass]="{'price-page-style': applyCustomStyling}">
            <div class="separator" [ngClass]="{'price-page-style': applyCustomStyling}"></div>
            <div class="lesson-per-week-title">
                <span translate>texts.Buy_Package_After_Booking_Tutor_Type_Question</span>
                <span class="info-icon tooltip">
                    <app-svg-icon name="question-icon"></app-svg-icon>
                    <span class="tooltiptext" translate>texts.Package_Tool_Tutor_Level_Expanded_Text</span>
                </span>
            </div>
            <div class="buttons-container">
                <button *ngFor="let level of tutorLevels" class="small-button" (click)="tutorLevelChange(level)"
                    [(class.small-button-active)]="level.level == selectedTutorLevel">
                    <div class="toggle-button" [(class.toggle-button-active)]="level.level == selectedTutorLevel">
                    </div>
                    <div class="button-text">
                        {{level.seniority|translate}}
                    </div>
                </button>
            </div>
        </div>
    </div>
    <div *ngIf="authService.userDetails?.studentDetails?.expectedTutoringDuration !== '0' && !showOnly5HourPackage"
        class="button cta-button" (click)="openNextStep()"
        [ngClass]="{'hidden': !selectedLessonPerWeek || (showTutorLevelOptions && !selectedTutorLevel)}" translate>
        texts.Buy_Package_After_Booking_Options_Button
    </div>
    <div *ngIf="authService.userDetails?.studentDetails?.expectedTutoringDuration === '0' || showOnly5HourPackage"
        class="button cta-button" (click)="openNextStep()" [ngClass]="{'hidden': !selectedTutorLevel}" translate>
        texts.Buy_Package_After_Booking_Options_Button
    </div>
</div>