import { WebsocketService } from './services/websocket.service';
import { Router } from '@angular/router';
import { AuthService, ToastService } from 'app/services';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { isPlatformServer } from '@angular/common';
import { UtilityService } from "./services";
import { MessageType, BypassAPIGlobalHandleEnums } from './constants';

@Injectable()
export class AppHttpInterceptor implements HttpInterceptor {

  constructor(
    private authService: AuthService,
    private utilityService: UtilityService,
    private router: Router,
    private websocketService: WebsocketService,
    private toastService: ToastService,
    @Inject(PLATFORM_ID) private platformId,
  ) {
  }

  intercept(originalRequest: HttpRequest<any>, next: HttpHandler): Observable<any> {
    // Append the session ID header, unless we are getting the translation data that creates a CORS problem on local envs
    let request = originalRequest.url.includes('translation/data') || originalRequest.url.includes('google') ?
      originalRequest :
      originalRequest.clone({ headers: originalRequest.headers.append("X-Session-ID", [this.utilityService.sessionId]) });
    return next.handle(request).pipe(
      catchError((response: HttpErrorResponse) => {
        // don't use interceptor on server
        if (isPlatformServer(this.platformId))
          return throwError(response);

        if (response.status === 403) {
          if (!request.url.includes(`/auth/login`)) {
            this.router.navigate(['/403']);
            return EMPTY;
          } else {
            return throwError(response);
          }
        }

        // forward all non-401 errors
        if (response.status !== 401) {
          let error = response.error.error;
          if (!error) {
            return throwError(response);
          }
          if (error.reason_keys && request.headers.get(BypassAPIGlobalHandleEnums.ToastMessageHandle) != 'true') {
            error.reason_keys.forEach(key => {
              this.toastService.showMsg('texts.' + key, MessageType.error)
            })
          }
          if (request.headers.get(BypassAPIGlobalHandleEnums.ErrorHandle) == 'true') {
            return throwError(response);
          }
          return EMPTY;
        }

        // forward 401 error on refresh or login url
        if (request.url.includes(`/auth/refresh`) || request.url.includes(`/auth/login`))
          return throwError(response);

        // retry all other 401 errors by refreshing the token first, then repeating the original request
        return this.authService.refresh().pipe(
          switchMap((token) => {
            if (token) {
              return next.handle(request.clone({
                setHeaders: {
                  Authorization: `Bearer ${token}`,
                },
              }));
            } else {
              this.authService.clearSession();
              this.router.navigate(['/login']);
              return throwError(() => response);
            }
          }),
          catchError(error => {
            if (error.headers?.status == 401 || error.text_key === "Misc_NotAuthenticated") {
              this.router.navigate(['/401']);
              this.websocketService.close();
            }
            return throwError(() => error);
          })
        );
      }));
  }



}
