import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { UtilityService } from '../../../services';
import { CmsBaseComponent } from '../cms-base/cms-base.component';

@Component({
	selector: 'app-table',
	templateUrl: './table.component.html',
	styleUrls: ['./table.component.scss']
})
export class TableComponent extends CmsBaseComponent implements OnInit {

	anchorLink?: string;
	title!: string;
	description!: string;
	contentsHead!: { text: any, type: string; }[];
	contentsBody!: [];
	transparentBackground: boolean = false;
	transparentLines: boolean = false;
	noFilter: boolean = false;

	adminTableComponent: any;

	private _sectionBackgroundColor: string = '';
	sectionBackground?: SafeStyle;
	get sectionBackgroundColor(): string {
		return this._sectionBackgroundColor;
	}
	@Input() set sectionBackgroundColor(val: string) {
		this._sectionBackgroundColor = val;
		this.sectionBackground = this._sanitizer.bypassSecurityTrustStyle(this.sectionBackgroundColor);
	}

	rows = [];
	temp = [];
	columns: any[] = [];

	datatableMessages = {
		emptyMessage: '',
		totalMessage: ''
	};

	constructor(
		private _sanitizer: DomSanitizer,
		private translationService: TranslateService,
		public utilityService: UtilityService,
	) {
		super();
	}

	ngOnInit(): void {
		super.ngOnInit();

		this.translationService.get('texts.AppointmentSection_length_menu', { value: '' })
			.subscribe((translation: string) => this.datatableMessages.totalMessage = translation);
		this.translationService.get('texts.AppointmentSection_empty_table', { value: '' })
			.subscribe((translation: string) => this.datatableMessages.emptyMessage = translation);

		for (const value of this.contentsHead) {
			this.columns.push({
				name: value.text,
				type: value.type
			});
		}
		this.rows = [...this.contentsBody];

		this.adminTableComponent = {
			'data': this.contentsBody
		};
	}

}
