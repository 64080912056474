import { ConfirmDialogComponent } from './../modules/shared/dialogs/confirm-dialog/confirm-dialog.component';
import { Observable } from 'rxjs';
import { DialogDataModel } from './../models/dialog-data.model';
import { DialogCoreComponent } from './../modules/shared/dialogs/dialog-core/dialog-core.component';
import { Injectable, EventEmitter } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { MatDialog as NewMatDialog, MatDialogRef as NewMatDialogRef } from '@angular/material/dialog';
import { UtilityService } from './utility.service';
import { InfoDialogComponent } from 'app/modules/shared/dialogs/info-dialog/info-dialog.component';
import { StudentRegisterDialogComponent } from 'app/standalone-components/student-login-or-register/student-register-dialog/student-register-dialog.component';
import { LoginDialogComponent } from 'app/standalone-components/student-login-or-register/login-dialog/login-dialog.component';

@Injectable({
	providedIn: 'root'
})
export class PopupService {

	isModal: boolean = false;
	isLoginModal: boolean = false;
	isLoginOrRegisterModal: boolean = false;
	isRegisterModal: boolean = false;

	//TODO remove when refactor the booking flows
	onClose = new EventEmitter();

	constructor(
		private utilityService: UtilityService,
		private matDialog: MatDialog,
		private newMatDialog: NewMatDialog
	) { }

	showLoginModal() {
		this.utilityService.trackEvent('popup', 'login', 'show');
		return this.matDialog.open(LoginDialogComponent, {
			minWidth: '100vw',
			maxWidth: '100vw',
		})
	}

	showRegisterModal() {
		this.utilityService.trackEvent('popup', 'register', 'show');
		return this.matDialog.open(StudentRegisterDialogComponent, {
			minWidth: '100vw',
			maxWidth: '100vw',
		})
	}

	openDialog<T>(data: DialogDataModel): Observable<T> {
		return new Observable(observer => {
			const dialog = this.matDialog.open(DialogCoreComponent, { panelClass: data.styles ? [...data.styles] : [], disableClose: true, autoFocus: false });
			const _component = dialog.componentInstance;
			_component.title = data.title;
			_component.actionText = data.actionText;
			_component.titleIcon = data.titleIcon;
			_component.titleIconTooltip = data.titleIconTooltip
			_component.childComponent = data.component;
			_component.onChildReady.subscribe((childInstance: T) => {
				observer.next(childInstance)
				observer.complete();
			})
			if (data.trackEvent) {
				this.trackDialogEvent(data.trackEvent, dialog)
			}
		})
	}

	openResponsiveDialog(dialogComp, config?: MatDialogConfig): NewMatDialogRef<any> {
		return this.newMatDialog.open(dialogComp, {
			maxWidth: "100vw",
			maxHeight: "95vh",
			enterAnimationDuration: 0,
			exitAnimationDuration: 0,
			panelClass: config?.panelClass
		});
	}

	openInfoDialog(data: DialogDataModel) {
		const dialog = this.matDialog.open(DialogCoreComponent, { panelClass: ['default-dialog'], disableClose: true });
		const _component = dialog.componentInstance;
		_component.title = data.title;
		_component.childComponent = InfoDialogComponent;
		_component.onChildReady.subscribe((childInstance: InfoDialogComponent) => {
			childInstance.description = data.description
		})

		if (data.trackEvent) {
			this.trackDialogEvent(data.trackEvent, dialog)
		}
		return dialog;
	}

	// TODO refactor - this is over engineered
	openConfirmDialog(data: DialogDataModel): Observable<ConfirmDialogComponent> {
		return new Observable(observer => {
			const dialog = this.matDialog.open(DialogCoreComponent, { panelClass: ['default-dialog'], disableClose: true, autoFocus: false });
			const _component = dialog.componentInstance;
			_component.title = data.title;
			_component.actionText = null;
			_component.childComponent = ConfirmDialogComponent;
			_component.onChildReady.subscribe((childInstance: ConfirmDialogComponent) => {
				childInstance.text = data.description
				observer.next(childInstance)
				observer.complete();
			})
			if (data.trackEvent) {
				this.trackDialogEvent(data.trackEvent, dialog)
			}
		})
	}

	private trackDialogEvent(trackEvent: string, dialog: MatDialogRef<any>) {
		this.utilityService.trackEvent('popup', trackEvent, 'show')
		dialog.afterClosed().subscribe(() => {
			this.utilityService.trackEvent('popup', trackEvent, 'hide')
		})
	}

}
