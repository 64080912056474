import { Location } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { CookieOptions, CookieService } from 'ngx-cookie';
import { MessageType } from '../../../constants';
import { AuthService, ToastService, ApiService } from '../../../services';
import { CmsBaseComponent } from '../cms-base/cms-base.component';

@Component({
	selector: 'app-contact-popup',
	templateUrl: './contact-popup.component.html',
	styleUrls: ['./contact-popup.component.scss']
})
export class ContactPopupComponent extends CmsBaseComponent implements OnInit {
	/*
	INSERT INTO `section` (`component`, `page`, `position`, `data`)
	VALUES ('contact-popup-section', '31', '9', '{\r\n  \"title\": \"My Title\",\r\n  \"description\": \"My Description\",\r\n  \"mobileTitle\": \"Mobile Action\",\r\n  \"namePlaceholder\": \"Name Placeholding\",\r\n  \"emailPlaceholder\": \"Email placeholding\",\r\n  \"buttonText\": \"My button\",\r\n  \"textColor\": \"#ffffff\",\r\n  \"sectionBackgroundColor\": \"#5cc75f\",\r\n  \"buttonBackgroundColor\": \"#486066\"\r\n}');
	*/

	@Input()
	title: string = "texts.ContactPopupSection_title";
	@Input()
	description?: string;
	@Input()
	mobileTitle?: string;
	@Input()
	namePlaceholder: string = 'texts.ContactFormSection_name';
	@Input()
	emailPlaceholder: string = 'texts.Misc_field_email';
	@Input()
	buttonText: string = "texts.ContactPopupSection_send";

	private _textFontColor: string = '';
	textFontColor?: SafeStyle;
	get textColor() {
		return this._textFontColor;
	}
	@Input() set textColor(val: string) {
		this._textFontColor = val;
		this.textFontColor = this._sanitizer.bypassSecurityTrustStyle(this.textColor);
	}

	private _sectionBackgroundColor: string = '';
	sectionBackground?: SafeStyle;
	get sectionBackgroundColor() {
		return this._sectionBackgroundColor;
	}
	@Input() set sectionBackgroundColor(val: string) {
		this._sectionBackgroundColor = val;
		this.sectionBackground = this._sanitizer.bypassSecurityTrustStyle(this.sectionBackgroundColor);
	}

	private _buttonBackgroundColor: string = '';
	buttonBackground?: SafeStyle;
	get buttonBackgroundColor() {
		return this._buttonBackgroundColor;
	}
	@Input() set buttonBackgroundColor(val: string) {
		this._buttonBackgroundColor = val;
		this.buttonBackground = this._sanitizer.bypassSecurityTrustStyle(this.buttonBackgroundColor);
	}

	isMinified: boolean = true;
	isSubmitted: boolean = false;
	isWrapperVisible: boolean = true;

	contactForm: UntypedFormGroup;
	emailValue: string = '';
	nameValue: string = '';

	location: Location;

	constructor(
		public authService: AuthService,
		private cookieService: CookieService,
		private formBuilder: UntypedFormBuilder,
		private apiService: ApiService,
		private toastService: ToastService,
		private _sanitizer: DomSanitizer,
		location: Location
	) {
		super();
		this.location = location;
		this.contactForm = this.formBuilder.group({
			email: '',
			name: '',
		});
	}

	ngOnInit(): void {
		super.ngOnInit();
		if (!this.buttonText) {
			this.buttonText = "ContactPopupSection_send";
		}
		if (!this.title) {
			this.title = "ContactPopupSection_title";
		}
		if (!this.mobileTitle) {
			this.mobileTitle = this.title;
		}
		this.isSubmitted = this.cookieService.get('contactpopup') ? true : false;

		this.isWrapperVisible = true;

		this.contactForm = new UntypedFormGroup({
			email: new UntypedFormControl(this.emailValue, [
				Validators.required,
				Validators.email,
			]),
			name: new UntypedFormControl(this.nameValue, [
				Validators.required,
			]),
		});
	}

	get email() { return this.contactForm.get('email'); }
	get name() { return this.contactForm.get('name'); }

	// contact popup hide
	closeContactPopupSection(): void {
		this.isMinified = true;
	}

	// contact popup show
	showContactPopup(): void {
		this.isMinified = false;
	}

	submitContactInfo(): void {

		if (this.contactForm.valid) {
			const tempURL = this.location.path();
			let data = {
				name: this.email ? this.email.value : null,
				email: this.name ? this.name.value : null,
				url: tempURL,
			};
			this.apiService.post(`api/contact/request/popup`, data).subscribe(() => {
				this.toastService.showMsg('texts.<i class="fas fa-check"></i>', MessageType.success);
				this.isWrapperVisible = false;
				this.isSubmitted = true;

				const cookieOptions: CookieOptions = {
					expires: (86400 * 30).toString(),
				};
				this.cookieService.put('contactpopup', "1", cookieOptions);
			});
		}
	}

}
