import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { Location } from '@angular/common';

import {
  LocalizeParser,
  LocalizeRouterModule,
  LocalizeRouterSettings,
  ManualParserLoader
} from '@gilsdav/ngx-translate-router';
import { TranslateService } from '@ngx-translate/core';
import { PageLoadComponent } from './modules/cms/page-load/page-load.component';
import { ErrorPageComponent } from './modules/pages/error-page/error-page.component';
import { AuthGuard } from './guards/auth.guard';
import { CanDeactivateGuard } from './guards/canDeactivate.guard';
import { PageLoadService, UtilityService } from './services';

export function createTranslateLoader(translate: TranslateService, location: Location, settings: LocalizeRouterSettings, utilityService: UtilityService) {
  return new ManualParserLoader(translate, location, settings, [utilityService.country], 'ROUTES.', '!');
}

const routes: Routes = [
  {
    path: 'Modal_password_forgot',
    redirectTo: 'Misc_auth_module/Modal_password_forgot',
    pathMatch: 'full'
  },
  {
    path: 'Auth_password_change',
    redirectTo: 'Misc_auth_module/Auth_password_change',
    pathMatch: 'full'
  },
  {
    path: 'Modal_password_forgot_confirmation',
    redirectTo: 'Misc_auth_module/Modal_password_forgot_confirmation',
    pathMatch: 'full'
  },
  {
    path: 'Auth_email_verify',
    redirectTo: 'Misc_auth_module/Auth_email_verify',
    pathMatch: 'full'
  },
  {
    path: 'Auth_email_change_successful',
    redirectTo: 'Misc_auth_module/Auth_email_change_successful',
    pathMatch: 'full'
  },
  {
    path: 'Misc_auth_module',
    loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: 'Misc_tutor_search',
    loadChildren: () => import('./modules/pages/tutor-search-page/tutor-search-page.module').then(m => m.TutorSearchPageModule)
  },
  {
    path: 'Navigation_edit_profile_student',
    redirectTo: 'Misc_edit_profile_module/Navigation_edit_profile_student',
    pathMatch: 'full'
  },
  {
    path: 'Misc_tutor_edit_profile',
    redirectTo: 'Misc_edit_profile_module/Misc_tutor_edit_profile',
    pathMatch: 'full'
  },
  {
    path: 'Misc_edit_profile_module',
    loadChildren: () => import('./modules/edit-profile/edit-profile.module').then(m => m.EditProfileModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'Misc_overview',
    loadChildren: () => import('./modules/overview/overview.module').then(m => m.OverviewModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'Misc_subscription', // TODO to be accessible by students only
    loadChildren: () => import('./modules/pages/subscription/subscription.module').then(m => m.SubscriptionModule)
  },
  {
    path: 'Misc_tutor_registration',
    loadComponent: () => import('./modules/tutor-registration/components/tutor-registration/tutor-registration.component').then(c => c.TutorRegistrationComponent),
    canDeactivate: [CanDeactivateGuard]
  },
  {
    path: 'Misc_tutor_registration/TeacherInfo',
    loadComponent: () => import('./modules/tutor-registration/components/tutor-information/tutor-information.component').then(c => c.TutorInformationComponent),
  },
  {
    path: 'Login_page',
    loadComponent: () => import('./standalone-components/login-page/login-page.component').then(c => c.LoginPageComponent),
  },
  {
    path: 'Register_page',
    loadComponent: () => import('./standalone-components/register-page/register-page.component').then(c => c.RegisterPageComponent),
  },
  {
    path: 'Navigation_messages',
    loadChildren: () => import('./modules/pages/chat/chat.module').then(m => m.ChatModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'Misc_ms_booking',
    loadChildren: () => import('./modules/msbooking/msbooking.module').then(m => m.MSBookingModule)
  },
  {
    path: 'Misc_Pricing',
    loadChildren: () => import('./modules/price/price.module').then(m => m.PriceModule)
  },
  {
    path: 'Misc_dashboard',
    loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'Misc_relation',
    loadChildren: () => import('./modules/relation/relation.module').then(m => m.RelationModule)
  },
  {
    path: 'Misc_profile',
    loadChildren: () => import('./modules/pages/tutor-profile/tutor-profile.module').then(m => m.TutorProfileModule)
  },
  {
    path: '401',
    component: ErrorPageComponent,
    data: { error: 401 }
  },
  {
    path: '403',
    component: ErrorPageComponent,
    data: { error: 403 }
  },
  {
    path: '404',
    component: ErrorPageComponent,
    data: { error: 404 }
  },
  {
    path: '500',
    component: ErrorPageComponent,
    data: { error: 500 }
  },
  {
    path: 'Misc_5_lesson_package',
    loadComponent: () =>
      import('./standalone-components/package-pages/lesson-5-package/lesson-5-package.component')
        .then((m) => m.Lesson5PackageComponent),
    canActivate: [AuthGuard]
  },
  {
    path: 'Misc_lesson_regular_package',
    loadComponent: () =>
      import('./standalone-components/package-pages/lesson-regular-packages/lesson-regular-packages.component')
        .then((m) => m.LessonRegularPackagesComponent),
    canActivate: [AuthGuard]
  },
  {
    path: 'Misc_alternative_package',
    loadComponent: () =>
      import('./standalone-components/package-pages/lesson-alternative-packages/lesson-alternative-packages.component')
        .then((m) => m.LessonAlternativePackagesComponent),
    canActivate: [AuthGuard]
  },
  {
    path: 'oauth',
    loadComponent: () =>
      import('./standalone-components/oauth-login/oauth.component')
        .then((m) => m.OauthComponent),
  },
  {
    path: '*/*',
    redirectTo: '/',
    pathMatch: 'full',
  },
  {
    path: '**',
    component: PageLoadComponent,
    data: { onSameUrlNavigation: 'reload', skipLocationChange: false },
    canActivate: [PageLoadService],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'disabled',
      useHash: false,
      scrollPositionRestoration: 'enabled'
    }),
    LocalizeRouterModule.forRoot(routes, {
      parser: {
        provide: LocalizeParser,
        useFactory: (createTranslateLoader),
        deps: [TranslateService, Location, LocalizeRouterSettings, UtilityService]
      },
      alwaysSetPrefix: false,
      initialNavigation: true
    })
  ],
  exports: [RouterModule, LocalizeRouterModule]
})
export class AppRoutingModule {
}
