import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService, UtilityService } from 'app/services';
import { CmsBaseComponent } from '../cms-base/cms-base.component';

@Component({
  selector: 'app-bonus-table',
  templateUrl: './bonus-table.component.html',
  styleUrls: ['./bonus-table.component.scss']
})
export class BonusTableComponent extends CmsBaseComponent implements OnInit {

  title!: string;
  description!: string;
  contentsHead!: { text: any, type: string; }[];
  contentsBody!: [];
  transparentBackground: boolean = false;
  transparentLines: boolean = false;
  noFilter: boolean = false;

  adminTableComponent: any;

  private _sectionBackgroundColor: string = '';
  sectionBackground?: SafeStyle;
  get sectionBackgroundColor(): string {
    return this._sectionBackgroundColor;
  }
  @Input() set sectionBackgroundColor(val: string) {
    this._sectionBackgroundColor = val;
    this.sectionBackground = this._sanitizer.bypassSecurityTrustStyle(this.sectionBackgroundColor);
  }

  rows = [];
  temp = [];
  columns: any[] = [];

  datatableMessages = {
    emptyMessage: '',
    totalMessage: ''
  };

  constructor(
    private _sanitizer: DomSanitizer,
    public authService: AuthService,
    private router: Router,
    private translationService: TranslateService,
    public utilityService: UtilityService,
    private activatedRoute: ActivatedRoute,
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();

    if (this.utilityService.isBrowser) {
      if (!this.authService.user) {
        setTimeout(() => {
          this.router.navigate(['/401'], { queryParams: { returnUrl: this.activatedRoute.snapshot.url } });
        });
      } else if (this.authService.isStudent()) {
        this.translationService.get(['ROUTES.Misc_dashboard', 'ROUTES.Misc_Dashboard_Student']).subscribe(links => {
          this.router.navigate([links['ROUTES.Misc_dashboard'], links['ROUTES.Misc_Dashboard_Student']]);
        });
      }
    }

    this.translationService.get('texts.AppointmentSection_length_menu', { value: '' })
      .subscribe((translation: string) => this.datatableMessages.totalMessage = translation);
    this.translationService.get('texts.AppointmentSection_empty_table', { value: '' })
      .subscribe((translation: string) => this.datatableMessages.emptyMessage = translation);

    for (const value of this.contentsHead) {
      this.columns.push({
        name: value.text,
        type: value.type
      });
    }
    this.rows = [...this.contentsBody];

    this.adminTableComponent = {
      'data': this.contentsBody
    };
  }

}
