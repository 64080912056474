<div class="popup-container">
    <div class="content">
        <div *ngIf="showCloseButton" class="close-button">
            <i class="fas fa-times" (click)="close()"></i>
        </div>
        <div class="title">
            {{ dialogTitle | translate }}
        </div>
        <div class="separator"></div>
        <div class="section-text">{{ dialogText | translate}}
        </div>
        <div class="button-container">
            <div *ngIf="showCancelButton" class="button cancel-button">
                <div class="login-or-register__button" (click)="close()">
                    {{ dialogCancelButton | translate }}
                </div>
            </div>
            <div class="button confirm-button">
                <div class="login-or-register__button" (click)="confirm()">
                    {{ dialogConfirmButton | translate }}
                </div>
            </div>
        </div>
    </div>
</div>