<div *ngIf="appointment" class="cancel-appointment">
  <div class="cancel-appointment__button-wrapper">
    <div class="cancel-appointment__button" (click)="submit()">
      <ng-container *ngIf="isTeacher && appointment.status != 'accepted'; else cancelAppointmentButton">
        {{'texts.CancelAppointmentDialog_cancel_button' | translate}}
      </ng-container>
      <ng-template #cancelAppointmentButton>
        {{'texts.CancelAppointmentDialog_accept_button' | translate}}
      </ng-template>
    </div>
  </div>
</div>