import { UtilityService } from '.';
import { ApiService } from './api.service';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import {
    ExamSubscriptionRequestModel,
    PackageRecommendationModel,
    PackageRecommendationRequestModel,
    PackageSubscriptionRequestModel,
    UserPackages
} from '../models/package.model';
import { PackagesSelectionOption } from 'app/models/packages-model';
import { PackageType } from 'app/constants';

@Injectable({
    providedIn: 'root'
})
export class PackagesFlowService {
    packageRecommendation: PackageRecommendationRequestModel
    package: PackageRecommendationModel;

    selectedDuration: PackagesSelectionOption;
    selectedLessonsPerWeek: PackagesSelectionOption;
    selectedTutorSeniority: PackagesSelectionOption;

    periodOptions: Array<PackagesSelectionOption>;
    sequenceOptions: Array<PackagesSelectionOption>;
    tutorSeniorityLevels: Array<PackagesSelectionOption>;

    totalCredits: string;

    constructor(
        private apiService: ApiService,
        private utilityService: UtilityService
    ) {
        this.packageRecommendation = {
            durationMonths: '4',
            lessonsPerWeek: '1',
            tutorCategory: '2',
            country: this.utilityService.country,
            improvementPurpose: '',
        }
    }

    getPackagesRecommendation(payload: PackageRecommendationRequestModel): Observable<PackageRecommendationModel> {
        return this.apiService.post('api/booking/packages/package-recommendation', payload)
    }

    getUserPackages(userId: number): Observable<UserPackages> {
        return this.apiService.get(`api/booking/packages/user-packages/current?userId=${userId}&statuses=[1,2,3]&cahceBuster=${Date.now()}`)
            .pipe(
                map(response => {
                    response.packages.map(p => {
                        if (!p.balance) {
                            p.balance = "0";
                        }

                        switch (p.status.toLocaleLowerCase()) {
                            case 'active':
                                p.status = PackageType.Active;
                                break;
                            case 'ended':
                                p.status = PackageType.Ended;
                                break;
                        }

                        return p;
                    });

                    response.packages.sort((a, b) => a.status - b.status);
                    this.totalCredits = response.packages.length ? (response.packages.map(x => x.status == 1 ? +x.balance : 0).reduce((a, b) => a + b)).toString() : ''
                    return response;
                })
            );
    }

    subscribeToPackage(data: PackageSubscriptionRequestModel): Observable<PackageRecommendationModel> {
        return this.apiService.post(`api/booking/packages/user-packages/subscribe`, data)
    }

    getExamPackages(tutorCategory: string): Observable<{ offers: Array<PackageRecommendationModel> }> {
        return this.apiService.post('api/booking/packages/exam-package-recommendation', { tutorCategory })
    }

    subcribeToExamPackage(data: ExamSubscriptionRequestModel): Observable<PackageRecommendationModel> {
        return this.apiService.post(`api/booking/packages/user-packages/subscribe-exam-package`, data)
    }

    clearUserPackages() {
        this.totalCredits = '';
    }
}

