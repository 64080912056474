import { SubjectsCheckboxListComponent } from './../../standalone-components/subjects-checkbox-list/subjects-checkbox-list.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchComponent } from './components/search/search.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { SharedModule } from '../shared/shared.module';
import { BottomSearchComponent } from './components/bottom-search/bottom-search.component';
import { ToggleOnlineHomeComponent } from 'app/standalone-components/toggle-online-home/toggle-online-home.component';
import { MaterialModule } from '../material/material.module';

@NgModule({
  declarations: [
    BottomSearchComponent,
    SearchComponent
  ],
  imports: [
    CommonModule,
    NgxDatatableModule,
    SharedModule,
    ToggleOnlineHomeComponent,
    SubjectsCheckboxListComponent,
    MaterialModule
  ],
  exports: [
    SearchComponent
  ]
})
export class SearchModule { }
