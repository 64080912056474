import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BuyPackageAfterBookingComponent } from 'app/standalone-components/new-packages/buy-package-after-booking/buy-package-after-booking.component';
import { SharedModule } from 'app/modules/shared/shared.module';
import { PackageCardsComponent } from '../package-cards/package-cards.component';
import { UserObj } from 'app/microservice-clients/user';

@Component({
  selector: 'app-lets-start-new-package',
  standalone: true,
  imports: [CommonModule, SharedModule, PackageCardsComponent, BuyPackageAfterBookingComponent],
  templateUrl: './lets-start-new-package.component.html',
  styleUrls: ['./lets-start-new-package.component.scss']
})
export class LetsStartNewPackageComponent {

  @Input() tutorObj: UserObj;
  @Input() showPictureAndSvg: boolean;
  @Input() showTutorLevelOptions: boolean;
  @Input() applyCustomStyling: boolean;
  tutorName: string;
  tutorLevel: string;
  selectedLessonPerWeek
  selectedTutorLevel
  currentStep = 1

  @Output() onClose = new EventEmitter();
  @Output() onNewPackagePurchase = new EventEmitter();

  ngOnInit() {
    if (this.tutorObj) {
      this.tutorName = this.tutorObj.personalInfo.forename
      this.tutorLevel = this.tutorObj.tutorDetails.category
    }
  }

  nextStep(lessonPerWeek, tutorLevel?) {
    this.selectedLessonPerWeek = lessonPerWeek
    this.selectedTutorLevel = tutorLevel
    this.currentStep++
  }

  previousStep() {
    this.currentStep--
  }
}