import { Component, EventEmitter, Output } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
@Component({
  selector: 'app-accept-appointment-dialog',
  templateUrl: './accept-appointment-dialog.component.html',
  styleUrls: ['./accept-appointment-dialog.component.scss']
})
export class AcceptAppointmentDialogComponent {

  @Output() onConfirm = new EventEmitter();
  @Output() onCancel = new EventEmitter();

  constructor(
    public dialogRef: MatDialogRef<AcceptAppointmentDialogComponent>
  ) { }

  submit(): void {
    this.onConfirm.emit();
  }

  cancel(): void {
    this.onCancel.emit();
  }
}
