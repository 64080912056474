import { Component, Input, ViewChild } from '@angular/core';
import { MatAccordion } from '@angular/material/expansion';
import { FaqItem } from 'app/models';

@Component({
  selector: 'app-faq-list',
  templateUrl: './faq-list.component.html',
  styleUrls: ['./faq-list.component.scss']
})
export class FaqListComponent {

  @ViewChild(MatAccordion) accordion: MatAccordion;

  @Input() faqTitle: string;
  @Input() faqListItems: Array<FaqItem>;
  @Input() isChatPage: boolean = false;
}