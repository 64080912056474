import { TimeSlot } from './../models/day-time-and-hours';
import { CardContent, LogType } from '../models';

export enum AppointmentStatus {
  pending = 0,
  accepted = 1,
  taken = 2,
  canceled = -1,
}

export const appointmentStatusText = {
  "0": 'pending',
  "1": 'accepted',
  "2": 'taken',
  "-1": 'canceled',
};

export enum BookingType {
  BookTeacher = 0,
  BookStudent = 1,
  BookConsultation = 2,
}

export enum MessageType {
  error = 'error',
  info = 'info',
  success = 'success',
  warning = 'warning'
}

export enum SitemapType {
  zero = 0,
  one = 1,
  two = 2,
  three = 3,
  four = 4,
  five = 5,
  six = 6,
  seven = 7,
  eight = 8,
  nine = 9,
  ten = 10
}

export enum UserRole {
  admin = 3,
  teacher = 2,
  student = 1,
  signedout = 0
}

export enum BypassAPIGlobalHandleEnums {
  ToastMessageHandle = 'bypassGlobalToastMessage',
  ErrorHandle = 'bypassGlobalErrorHandling',
  All = 'bypassAllAPIGlobalHandle'
}

export const Brand = {
  de: {
    name: 'Lernigo',
    language: 'de',
    domain: 'lernigo.de',

    trustPilot: {
      businessUnitID: '60118c67a3425300017d7832',
      businessName: 'lernigo.de',
      apiKey: '3n2jdJo42DG9fFDwGsGSA27Te2rjfTDj'
    },
  },
  nl: {
    name: 'Bijles Aan Huis',
    language: 'nl',
    domain: 'bijlesaanhuis.nl',

    trustPilot: {
      businessUnitID: '5f37fb8b892522000190d0a2',
      businessName: 'bijlesaanhuis.nl',
      apiKey: 'ektaxGyC7PqAjJdxE4d7YzAO1TJPX6D9'
    },
  },
};
let CardNumberSection_img_desktop_src: "https://d3oweo1tlra0ga.cloudfront.net/icoontjes/bijles-docent.png"
let CardNumberSection_img_calendar_src: "https://d3oweo1tlra0ga.cloudfront.net/icoontjes/bijles-afspraak.png"
let CardNumberSection_img_graph_src: "https://d3oweo1tlra0ga.cloudfront.net/icoontjes/bijles-resultaat.png"
let CardNumberSection_img_desktop_src_de: "https://d3oweo1tlra0ga.cloudfront.net/symbole/nachhilfe-lehrer.png"
let CardNumberSection_img_calendar_src_de: "https://d3oweo1tlra0ga.cloudfront.net/symbole/nachhilfe-plant.png"
let CardNumberSection_img_graph_src_de: "https://d3oweo1tlra0ga.cloudfront.net/symbole/nachhilfe-ergebnis.png"


export const defaultCardContent: { [key: string]: { [key: string]: CardContent; }; } = {
  "nl": {
    "CardBasicSection_img_tutor": {
      "imgAltText": "Niet goed? Geld terug!",
      "imgSrc": "https://d3oweo1tlra0ga.cloudfront.net/icoontjes/bijles-helpen.png",
      "title": "Niet goed? Geld terug!",
      "description": "Wij hebben door onze zorgvuldige manier van selecteren veel vertrouwen in onze bijlesdocenten. Indien de bijlesleraar niet voldoet aan de kwaliteitseisen die wij stellen, dan wordt de bijles door ons betaald!"
    },
    "CardBasicSection_img_student": {
      "imgAltText": "Docenten op jou afgestemd",
      "imgSrc": "https://d3oweo1tlra0ga.cloudfront.net/icoontjes/bijles-leerling.png",
      "title": "Docenten op jou afgestemd",
      "description": "Selecteer zelf de docent die bij je wensen past. De gewenste docent komt meestal zonder reiskosten naar jou toe, zodat je goed voorbereid bent op toetsen."
    },
    "CardBasicSection_img_help": {
      "imgAltText": "Wij helpen persoonlijk!",
      "imgSrc": "https://d3oweo1tlra0ga.cloudfront.net/icoontjes/bijles-garantie.png",
      "title": "Wij helpen persoonlijk!",
      "description": "Ons team is zowel telefonisch als via WhatsApp, e-mail of de chat bereikbaar om ervoor te zorgen dat jouw begeleiding perfect is. Zij zijn getraind om jou te koppelen aan de bijlesdocent die het best past bij je persoonlijke wensen. "
    },
    "CardNumberSection_img_desktop": {
      "imgAltText": "Kies je docent",
      "imgSrc": CardNumberSection_img_desktop_src,
      "imgsources": [
        {
          "src": CardNumberSection_img_desktop_src,
          "maxScreenSize": "991",
          "minScreenSize": null
        },
        {
          "src": CardNumberSection_img_desktop_src,
          "maxScreenSize": null,
          "minScreenSize": "992"
        }
      ],
      "title": "Kies je docent",
      "description": "Ben je op zoek naar aanvullend onderwijs, op de leerling afgestemd? Selecteer de docent die het best bij jou past aan de hand van het docentenprofiel. Onze helpdesk adviseert graag bij het maken van de keuze!"
    },
    "CardNumberSection_img_calendar": {
      "imgAltText": "Maak een afspraak",
      "imgSrc": CardNumberSection_img_calendar_src,
      "imgsources": [
        {
          "src": CardNumberSection_img_calendar_src,
          "maxScreenSize": "991",
          "minScreenSize": null
        },
        {
          "src": CardNumberSection_img_calendar_src,
          "maxScreenSize": null,
          "minScreenSize": "992"
        }
      ],
      "title": "Maak een afspraak ",
      "description": "Maak via onze site gelijk een afspraak met de bijlesdocent voor bijles, examentraining of huiswerkbegeleiding. Vraag gerust eerst om een vrijblijvende en kosteloze kennismaking! "
    },
    "CardNumberSection_img_graph": {
      "imgAltText": "Vooruitgang boeken!",
      "imgSrc": CardNumberSection_img_graph_src,
      "imgsources": [
        {
          "src": CardNumberSection_img_graph_src,
          "maxScreenSize": "991",
          "minScreenSize": null
        },
        {
          "src": CardNumberSection_img_graph_src,
          "maxScreenSize": null,
          "minScreenSize": "992"
        }
      ],
      "title": "Vooruitgang boeken!",
      "description": "Zodra je de beste docent hebt gevonden, kan aan de hand van persoonlijke begeleiding de vooruitgang starten. De bijlesdocent begint graag op korte termijn met een goed plan voor betere schoolresultaten!"
    }
  },
  "de": {
    "CardBasicSection_img_tutor": {
      "imgAltText": "Nachhilfelehrer an der Tafel.",
      "imgSrc": "https://d3oweo1tlra0ga.cloudfront.net/symbole/nachhilfe-garantie.png",
      "title": "Nicht zufrieden? Geld zur\u00fcck!",
      "description": "Wir haben durch sorgf\u00e4ltige Auswahlverfahren gro\u00dfes Vertrauen in unsere Nachhilfelehrer. Falls sich der Lehrer nicht an unsere Qualit\u00e4tsstandards h\u00e4lt, bezahlen wir die Stunde!"
    },
    "CardBasicSection_img_student": {
      "imgAltText": "Sch\u00fcler im Klassenraum.",
      "imgSrc": "https://d3oweo1tlra0ga.cloudfront.net/symbole/nachhilfe-schueler.png",
      "title": "Lehrer auf Sch\u00fcler abgestimmt.",
      "description": "Du w\u00e4hlst selbst den Lehrer, der <span style='font-weight:bold;'>deinen W\u00fcnschen<\/span> entspricht. Dein Lehrer kommt oft ohne Reisekosten zu dir, sodass du eine ideale Pr\u00fcfungsvorbereitung bekommen kannst."
    },
    "CardBasicSection_img_help": {
      "imgAltText": "Der Helpdesk steht bereit.",
      "imgSrc": "https://d3oweo1tlra0ga.cloudfront.net/symbole/nachhilfe-hilfe.png",
      "title": "Wir helfen gerne!",
      "description": "Wir sind sowohl telefonisch, als auch via Mail, Chat, oder WhatsApp erreichbar, um daf\u00fcr zu sorgen, dass du eine optimale Nachhilfe genie\u00dft. Unser Team ist geschult darin, Sch\u00fcler mit den f\u00fcr sie passenden Lehrern zu verbinden."
    },
    "CardNumberSection_img_desktop": {
      "imgAltText": "W\u00e4hle deinen Nachhilfelehrer.",
      "imgSrc": CardNumberSection_img_desktop_src_de,
      "imgsources": [
        {
          "src": CardNumberSection_img_desktop_src_de,
          "maxScreenSize": "991",
          "minScreenSize": null
        },
        {
          "src": CardNumberSection_img_desktop_src_de,
          "maxScreenSize": null,
          "minScreenSize": "992"
        }
      ],
      "title": "W\u00e4hle deinen Lehrer.",
      "description": "Bist du auf der Suche nach Nachhilfe, die auf dich abgestimmt ist? W\u00e4hle deinen passenden Lehrer anhand des Tutorenprofils. Unser Helpdesk hilft dir dabei gerne!"
    },
    "CardNumberSection_img_calendar": {
      "imgAltText": "Mache einen Termin bei Lernigo.",
      "imgSrc": CardNumberSection_img_calendar_src_de,
      "imgsources": [
        {
          "src": CardNumberSection_img_calendar_src_de,
          "maxScreenSize": "991",
          "minScreenSize": null
        },
        {
          "src": CardNumberSection_img_calendar_src_de,
          "maxScreenSize": null,
          "minScreenSize": "992"
        }
      ],
      "title": "Mache direkt einen Termin.",
      "description": "Du kannst \u00fcber unsere Website direkt mit deinem Lehrer einen Termin zur Nachhilfe, Hausaufgabenbetreuung, oder zur Pr\u00fcfungsvorbereitung vereinbaren. Mache auch einen Termin zum kostenlosen Kennenlerngespr\u00e4ch!"
    },
    "CardNumberSection_img_graph": {
      "imgAltText": "Bessere Schulnoten mit Nachhilfe.",
      "imgSrc": CardNumberSection_img_graph_src_de,
      "imgsources": [
        {
          "src": CardNumberSection_img_graph_src_de,
          "maxScreenSize": "991",
          "minScreenSize": null
        },
        {
          "src": CardNumberSection_img_graph_src_de,
          "maxScreenSize": null,
          "minScreenSize": "992"
        }
      ],
      "title": "Mache Fortschritte!",
      "description": "Sobald der richtige Lehrer f\u00fcr dich gefunden wurde, k\u00f6nnen die Fortschritte beginnen! Dein Lehrer f\u00e4ngt gerne direkt damit an, dir einen Plan f\u00fcr bessere Noten zu erstellen!"
    },
  }
};

//TODO when refactoring Login comp, get rid of this obj
export const loginFields = {
  "title": "texts.Misc_log_in",
  "inputFields": [
    {
      "label": "texts.Misc_email",
      "inputIcon": {
        "icon": "fas fa-user-circle"
      },
      "input": {
        "id": "login-email",
        "name": "email",
        "type": "email",
        "value": "",
        "placeholder": "texts.Misc_email",
        "required": true
      }
    },
    {
      "label": "texts.LoginSection_password_placeholder",
      "inputIcon": {
        "icon": "fas fa-lock"
      },
      "input": {
        "id": "login-password",
        "name": "password",
        "type": "password",
        "value": "",
        "placeholder": "texts.LoginSection_password_placeholder",
        "required": true
      }
    }
  ],
  "inputSubmitButton": {
    "input": {
      "id": "login-modal",
      "value": "texts.LoginSection_button",
      "type": "submit"
    }
  },
  "footerContent": {
    "links": [
      {
        "text": "texts.LoginSection_footer_forgot_password",
        "link": "Modal_password_forgot"
      }
    ]
  }
};

export enum LoggerEvents {
  Flush = 1
}

export const LOG_TYPE: { [key: string]: LogType } = {
  DEBUG: {
    name: 'DEBUG',
    level: 100
  },
  INFO: {
    name: 'INFO',
    level: 200
  },
  WARNING: {
    name: 'WARNING',
    level: 300
  },
  ERROR: {
    name: 'ERROR',
    level: 400
  },
};

export enum PackageOpenLocations {
  login = 'login',
  register = 'register',
  booking = 'booking',
  pricePage = 'price-page',
  afterIntroSession = 'after-intro-session',
  beforeIntroSession = 'before-intro-session',
  firstMessage = 'first-message'
}

export const schedulerColors: any = {
  canceled: {
    primary: "#486066",
    secondary: "#eee",
  },
  pending: {
    primary: "#486066",
    secondary: "#FDFFAB",
  },
  accepted: {
    primary: "#5cc75f",
    secondary: "#5cc75f",
  },
  available: {
    primary: "#5cc75f",
    secondary: "#5cc75f",
  },
  rejected: {
    primary: "#486066",
    secondary: "#FFD8AB",
  }
};

export enum StudentTutorRelationStatus {
  none = "None",
  unknown = "UnknownRStatus",
  chatting = "RChatting",
  introducing = "RIntroducing",
  tutoring = "RTutoring",
  disengaged = "RDisengaged"
}

export enum AppointmentType {
  lesson = 1,
  introduction = 2
}

export enum StandardAppointmentDuration {
  NL = 60,
  DE = 45
}

export const morningHours: Array<TimeSlot> = [
  { hours: '8:00' },
  { hours: '8:15' },
  { hours: '8:30' },
  { hours: '8:45' },
  { hours: '9:00' },
  { hours: '9:15' },
  { hours: '9:30' },
  { hours: '9:45' },
  { hours: '10:00' },
  { hours: '10:15' },
  { hours: '10:30' },
  { hours: '10:45' },
  { hours: '11:00' },
  { hours: '11:15' },
  { hours: '11:30' },
  { hours: '11:45' }
];
export const middag: Array<TimeSlot> = [
  { hours: '12:00' },
  { hours: '12:15' },
  { hours: '12:30' },
  { hours: '12:45' },
  { hours: '13:00' },
  { hours: '13:15' },
  { hours: '13:30' },
  { hours: '13:45' },
  { hours: '14:00' },
  { hours: '14:15' },
  { hours: '14:30' },
  { hours: '14:45' }
];
export const namiddag: Array<TimeSlot> = [
  { hours: '15:00' },
  { hours: '15:15' },
  { hours: '15:30' },
  { hours: '15:45' },
  { hours: '16:00' },
  { hours: '16:15' },
  { hours: '16:30' },
  { hours: '16:45' },
  { hours: '17:00' },
  { hours: '17:15' },
  { hours: '17:30' },
  { hours: '17:45' }
];
export const avond: Array<TimeSlot> = [
  { hours: '18:00' },
  { hours: '18:15' },
  { hours: '18:30' },
  { hours: '18:45' },
  { hours: '19:00' },
  { hours: '19:15' },
  { hours: '19:30' },
  { hours: '19:45' },
  { hours: '20:00' },
  { hours: '20:15' },
  { hours: '20:30' },
  { hours: '20:45' },
  { hours: '21:00' },
  { hours: '21:15' },
  { hours: '21:30' },
  { hours: '21:45' }
];

export enum PackageType {
  Active = 1,
  Ended = 2
}

export enum Display {
  none = 'none',
  block = 'block'
}

// It is used in mutiple places to slow a bit down the naviagation while the bottomsheets or dialogs are closed
// we need this since we manipulate route params to provide a native app like experience while swiping back 
export const navigationSlower: number = 400;

export enum AppointmentApiType {
  introduction = "Introduction",
  lesson = "Lesson"
}