<div class="package-cards-container">
    <div class="info-container">
        <div class="info-title" #title translate>texts.Package_Cards_Component_Title</div>
        <div class="info-description" *ngIf="!isModificationsAllowed && !isExamMode" translate>
            texts.Package_Cards_Component_Body_Text
        </div>
        <div class="info-description" *ngIf="!isModificationsAllowed && isExamMode" translate>
            texts.Package_Cards_Component_Body_Exam_Text
        </div>
    </div>
    <div class="cards-container" [ngClass]="{'alternative-packages': showAlternativePackages}">
        <div class="card-container" *ngFor="let card of packageCards">
            <div class="price-badge" *ngIf="isUserIdOdd || card._isExamPackageVersion">
                {{ (card._isExamPackageVersion ? card._model.price : card.pricePerMonth) |
                noDecimalCurrencyFormatter}} &nbsp;
                <span *ngIf="!card._isExamPackageVersion" translate>texts.Misc_Monthly
                </span>
                <span *ngIf="card._isExamPackageVersion" translate>texts.price-total
                </span>
            </div>
            <div class="price-badge" *ngIf="!isUserIdOdd && !card._isExamPackageVersion">
                <span>{{ 'texts.price-total' | translate | titlecase }}:&nbsp;</span>
                <div>
                    {{ card._totalPrice |
                    noDecimalCurrencyFormatter}},-
                </div>
            </div>
            <div class="details-and-button">
                <div class="package-details">
                    <div class="duration" *ngIf="isUserIdOdd || card._isExamPackageVersion">{{
                        card._isExamPackageVersion ? card._model.totalLessons :
                        card.durationMonths}}&nbsp;
                        <span *ngIf="card._isExamPackageVersion" translate> texts.tutoring-hours</span>
                        <span *ngIf="!card._isExamPackageVersion" translate> texts.Misc_Months</span>
                    </div>
                    <div class="duration duration-hours" *ngIf="!isUserIdOdd && !card._isExamPackageVersion">
                        {{card._totalLessonsInHours}}&nbsp;
                        <div translate>
                            texts.Package_Cards_Component_Hours
                        </div>
                    </div>
                    <span *ngIf="isUserIdOdd && card._isExamPackageVersion" class="exam-flex-details" translate>
                        texts.package-flexible-spent
                    </span>
                    <div *ngIf="isUserIdOdd && !card._isExamPackageVersion" class="lesson-per-month"
                        [translateParams]="{lessonPerMonth: lessonPerMonth}" translate>
                        texts.Package_Cards_Component_Lessonspermonth</div>
                    <div class="lesson-per-month" *ngIf="!isUserIdOdd && !card._isExamPackageVersion" translate
                        [translateParams]="{ durationMonth : card.durationMonths}">
                        texts.Package_Cards_Component_Package_Duration_Month
                    </div>
                    <div class="cost-per-hour-container">
                        <div class="cost">{{card.costPerHour | currencyFormatter}}</div>
                        <div class="hour">&nbsp;<span translate>texts.Misc_Hourly</span></div>
                    </div>
                </div>
                <button class="select-button" (click)="packageSelectedToBuy=card; buyPackage()"><span
                        translate>texts.Misc_select</span></button>
            </div>
        </div>
    </div>
    <div class="additional-info-container">
        <div class="additional-info-text" translate>texts.Package_Cards_Component_Additional_Info_Text &nbsp;</div>
        <a class="additional-info-link" [routerLink]="['/Misc_terms'] | localize" target="_blank" translate>
            texts.Package_Cards_Component_Additional_Info_Link</a>
    </div>
    <div class="money-back" translate>texts.Package_Cards_Component_Money_back_guarantee_text</div>
    <app-faq-list [faqTitle]="faqTitle" [faqListItems]="faqListItems"></app-faq-list>
</div>