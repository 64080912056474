<section [id]="anchorLink" class="table-section" [style.background]="sectionBackgroundColor">
	<div class="table-section__table">
		<h1 *ngIf="title && isFirstSection" class="table-section__title" [innerHTML]="title | translate"></h1>
		<h2 *ngIf="title && !isFirstSection" class="table-section__title" [innerHTML]="title | translate"></h2>
		<div class="table-section__description" [innerHTML]="description | unescape"></div>
		<ngx-datatable *ngIf="utilityService.isBrowser" [rows]="rows" class="material" [columnMode]="'force'" [headerHeight]="50" [footerHeight]="50"
			[rowHeight]="'auto'" [columns]="columns" [reorderable]="true" [messages]="datatableMessages" class="table"
			[ngClass]="{
						'bg-transparent': transparentBackground,
						'lines-transparent': transparentLines,
						'no-filter': noFilter
					}">

			<ngx-datatable-column *ngFor="let column of columns; let i = index;" [name]="column.name | translate"
				prop="{{column.prop}}">
				<ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
					<span *ngIf="column.type == 'button'">
						<a [href]="row[i].link">
							<input type="button" class="table-section__button" [value]="row[i].text | translate" />
						</a>
					</span>
					<span *ngIf="column.type == 'download'">
						<a [href]="row[i].link" target="_blank">
							<i *ngIf="!row[i].text; else hasText" class="far fa-file-alt"></i>
							<ng-template #hasText>
								{{row[i].text | translate}}
							</ng-template>
						</a>
					</span>
					<span *ngIf="column.type == 'func'">
						<input type="button" class="table-section__button" (click)="row[i].func" [value]="row[i].text | translate" />
					</span>
					<span *ngIf="column.type == 'link'">
						<a class="table-section__link" [href]="row[i].link">{{row[i].text | translate}}</a>
					</span>
					<span *ngIf="column.type == 'text'">
						<p class="table-section__text">{{row[i].text | translate}}</p>
					</span>
				</ng-template>
			</ngx-datatable-column>
		</ngx-datatable>

	</div>

</section>