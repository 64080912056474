import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { LazyLoadImageModule, LAZYLOAD_IMAGE_HOOKS } from 'ng-lazyload-image';
import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';
import { InfoModule } from '../info/info.module';
import { SharedModule } from '../shared/shared.module';
import { ActionButtonComponent } from './action-button/action-button.component';
import { BlogPostSectionComponent } from './blog-post-section/blog-post-section.component';
import { CardModule } from '../card/card.module';
import { CenterTextSectionComponent } from './center-text-section/center-text-section.component';
import { ContactFormSectionComponent } from './contact-form-section/contact-form-section.component';
import { ContactHeaderSectionComponent } from './contact-header-section/contact-header-section.component';
import { CountingNumberComponent } from './counting-number/counting-number.component';
import { FollowUpButtonSectionComponent } from './follow-up-button-section/follow-up-button-section.component';
import { LinkListSectionComponent } from './link-list-section/link-list-section.component';
import { LogoGroupComponent } from './logo-group/logo-group.component';
import { PageListSectionComponent } from './page-list-section/page-list-section.component';
import { QuestionCollectionSectionComponent } from './question-collection/question-collection-section/question-collection-section.component';
import { QuestionCollectionCardComponent } from './question-collection/question-collection-card/question-collection-card.component';
import { SearchbarHeaderSectionComponent } from './searchbar-header-section/searchbar-header-section.component';
import { TableComponent } from './table/table.component';
import { SitemapComponent } from './sitemap/sitemap.component';
import { ContactPopupComponent } from './contact-popup/contact-popup.component';
import { CmsBaseComponent } from './cms-base/cms-base.component';
import { ActionHeaderComponent } from './action-header/action-header.component';
import { ButtonSectionComponent } from './button-section/button-section.component';
import { LazyLoadImageHooks } from '../../hooks/lazy-load-image-hooks';
import { StorybrandStepsComponent } from './storybrand-steps/storybrand-steps.component';
import { EmpathyStatementComponent } from './empathy-statement/empathy-statement.component';
import { AppearDirective } from './appear/appear.directive';
import { PageLoadComponent } from './page-load/page-load.component';
import { ErrorPageModule } from '../pages/error-page/error-page.module';
import { HubspotSectionComponent } from './hubspot-section/hubspot-section.component';
import { ActionHeaderTwoComponent } from './action-header-two/action-header-two.component';
import { SearchbarSectionComponent } from './searchbar-section/searchbar-section.component';
import { LinkTabSectionComponent } from './link-tab-section/link-tab-section.component';
import { InfoPageModule } from '../info-page/info-page.module';
import { BonusTableComponent } from './bonus-table/bonus-table.component';
import { SearchModule } from '../search/search.module';
import { CmsSectionWrapperComponent } from './cms-section-wrapper/cms-section-wrapper.component';
import { GoogleDefaultReviewComponent } from 'app/standalone-components/google-reviews/google-default-review/google-default-review.component';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
	declarations: [
		ActionButtonComponent,
		ActionHeaderComponent,
		ButtonSectionComponent,
		BlogPostSectionComponent,
		CenterTextSectionComponent,
		ContactFormSectionComponent,
		ContactHeaderSectionComponent,
		ContactPopupComponent,
		CountingNumberComponent,
		CmsBaseComponent,
		FollowUpButtonSectionComponent,
		HubspotSectionComponent,
		LinkListSectionComponent,
		LogoGroupComponent,
		PageListSectionComponent,
		QuestionCollectionSectionComponent,
		QuestionCollectionCardComponent,
		SearchbarHeaderSectionComponent,
		SitemapComponent,
		TableComponent,
		ContactPopupComponent,
		StorybrandStepsComponent,
		EmpathyStatementComponent,
		PageLoadComponent,
		AppearDirective,
		ActionHeaderTwoComponent,
		SearchbarSectionComponent,
		LinkTabSectionComponent,
		BonusTableComponent,
		CmsSectionWrapperComponent
	],
	imports: [
		CardModule,
		InfoModule,
		ErrorPageModule,
		InfoPageModule,
		LazyLoadImageModule,
		NgxDatatableModule,
		RecaptchaModule,
		RecaptchaFormsModule,
		SharedModule,
		SearchModule,
		MatDialogModule,
		TranslateModule.forChild(),
		GoogleDefaultReviewComponent
	],
	exports: [
		ActionButtonComponent,
		ActionHeaderComponent,
		ButtonSectionComponent,
		BlogPostSectionComponent,
		CenterTextSectionComponent,
		ContactFormSectionComponent,
		ContactHeaderSectionComponent,
		ContactPopupComponent,
		CountingNumberComponent,
		CmsBaseComponent,
		FollowUpButtonSectionComponent,
		HubspotSectionComponent,
		LinkListSectionComponent,
		LogoGroupComponent,
		PageListSectionComponent,
		QuestionCollectionSectionComponent,
		SearchbarHeaderSectionComponent,
		TableComponent,
		SitemapComponent,
		PageLoadComponent,
		AppearDirective,
		BonusTableComponent,
		SearchbarSectionComponent,
		CmsSectionWrapperComponent
	],
	providers: [
		{
			provide: LAZYLOAD_IMAGE_HOOKS,
			useClass: LazyLoadImageHooks
		},
	]
})
export class CmsModule { }
