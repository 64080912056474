import { MatDialogRef } from '@angular/material/dialog';
import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmBaseComponent } from './confirm-base.component';
import { SharedModule } from 'app/modules/shared/shared.module';

@Component({
    selector: 'app-confirm-dialog-new',
    standalone: true,
    imports: [CommonModule, SharedModule],
    templateUrl: './confirm.component.html',
    styleUrls: ['./confirm.component.scss']
})
export class ConfirmDialogNewComponent extends ConfirmBaseComponent {

    constructor(
        private matDialogRef: MatDialogRef<ConfirmDialogNewComponent>
    ) {
        super();
        this.showCloseButton = true;
    }

    close() {
        this.matDialogRef.close();
        this.onClose.emit();
    }

    confirm() {
        this.matDialogRef.close();
        this.onConfirm.emit()
    }
}
