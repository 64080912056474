import { Appointment } from "./appointment.model"

export interface OverviewAppointmentModel {
  id: string,
  tutorID: string,
  studentID: string,
  date: string,
  time: string,
  timePeriod: string,
  user: string
  userLink: string,
  courseID: string,
  duration: number,
  bookedBy: string,
  online: boolean,
  cost: number,
  travel: number,
  location: string,
  status: string,
  intro: boolean,
  isCredits: boolean,
  cancellable: boolean,
  //used and generated on UI
  _showJoinButton: boolean,
  _isTodayAppointment: boolean,
  model: Appointment,
}

export interface OverviewPackageModel {
  id: string,
  startDate: string,
  dateEnd: string,
  cost: number,
  discount: number,
  creditsTotal: number,
  creditsRemaining: number,
  status: number,
  // used and generated on UI
  _isUIExpanded: boolean
}

export interface OverviewAppointmentFilterModel {
  begin?: string,
  end?: string
}

export interface OverviewFilterCriteriaModel {
  period: string,
  course: number
}

export interface OverviewFilterResponseModel {
  courses: Array<string>,
  periods: Array<string>
}

export interface OverviewAppointmentPeriodsModel {
  monthShort: string,
  monthVerbose: string
}
